import React, { useState, useMemo, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import GoogleMapNew from "./GoogleMapNew";
import GoogleMapContainer from "./GoogleMapContainer";
import CustomMap from "./CustomMap";
import makeApolloClient from "./utils/apollo";
import { ApolloProvider } from "@apollo/react-hooks";
import Geocoding from "./Geocoding";
import Excel from "./Excel";
import { LOGIN } from "./utils/services";

const App = () => {
	const [client, setClient] = useState(null);
	const [token, setToken] = useState(null);

	const generateToken = async () => {
		const generatedToken = await LOGIN({
			username: process.env.REACT_APP_USERNAME,
			password: process.env.REACT_APP_PASSWORD,
		});
		console.log(generatedToken, "generated token");
		const client = makeApolloClient(generatedToken);
		setClient(client);
	};

	useEffect(() => {
		if (!client) {
			generateToken();
		}
	}, [client]);

	// useEffect(() => {
	// 	const loadClient = async () => {
	// 		let client = await makeApolloClient();
	// 		setClient(client);
	// 	};
	// 	if (!client) {
	// 		loadClient();
	// 	}
	// }, []);

	if (!client) {
		console.log("error");
		return null;
	} else {
		console.log("app js");
		return (
			<ApolloProvider client={client}>
				<GoogleMapNew />
				{/* <Geocoding /> */}
				{/* <Excel /> */}
			</ApolloProvider>
		);
	}
};

export default App;
