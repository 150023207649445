import React, { useState } from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";

const MapMarker = (props) => {
	const { lat, lng, clusterer, address, status, name, map } = props;
	const [show, setShow] = useState(false);

	const makeid = (length) => {
		var result = "";
		var characters =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	};

	let randomId = makeid(30);

	return (
		<Marker
			key={randomId}
			position={{ lat, lng }}
			clusterer={clusterer}
			onClick={(marker) => {
				setShow(true);
			}}
			// onClick={(marker) => {
			// 	setSelectedMarker(marker);
			// }}
		>
			{show && (
				<InfoWindow position={{ lat, lng }} onClick={() => setShow(false)}>
					<div>
						<h4>{address}</h4>
						<h5>{`Patient Name: ${name}`}</h5>
						<h5>{`Status: ${status}`}</h5>
					</div>
				</InfoWindow>
			)}
		</Marker>
	);
};

export default MapMarker;
