import React, { useMemo, useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import MarkerClusterer from "@google/markerclusterer";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
	FETCH_CASES,
	FETCH_GEOCODING,
	INSERT_GEOCODING,
	FETCH_BUBBLE_DATA,
} from "./utils/queries";
import _ from "lodash";
import tap from "lodash/fp/tap";
import flow from "lodash/fp/flow";
import groupBy from "lodash/fp/groupBy";
import Geocode from "react-geocode";
import moment from "moment";
import ReactExport from "react-export-excel";
import Media from "react-media";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

Geocode.setApiKey("AIzaSyBP5hWnrSbLH3FzHQoS-8kXfXNtidCNlJE");

// set response language. Defaults to english.
Geocode.setLanguage("en");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("ph");

// Enable or disable logs. Its optional.
Geocode.enableDebug();

const Marker = ({ text }) => (
	<div
		style={{
			width: 50,
			height: 50,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		}}
	>
		{text}
	</div>
);

const GoogleMapContainer = () => {
	// componentDidMount() {
	// 	const script = document.createElement("script");
	// 	script.src =
	// 		"https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js";
	// 	script.async = true;
	// 	document.body.appendChild(script);
	// }

	// const [googleMapRef, setGoogleMapRef] = useState(null);
	// const [googleRef, setGoogleRef] = useState(null);

	const { data, error, loading } = useQuery(FETCH_CASES);
	const {
		data: bubbleData,
		error: bubbleError,
		loading: bubbleLoading,
	} = useQuery(FETCH_BUBBLE_DATA);
	const { data: geocoding } = useQuery(FETCH_GEOCODING);
	const [insertGeolocation] = useMutation(INSERT_GEOCODING);
	const [coords, setCoords] = useState([]);
	const [state, setState] = useState([]);
	const [markers, setMarkers] = useState([]);
	const [created, setCreated] = useState(null);

	const fetchLatLong = async (address) => {
		let result = null;
		await Geocode.fromAddress(address).then(
			(response) => {
				const { lat, lng } = response.results[0].geometry.location;
				result = { lat, lng, success: true };
			},
			(error) => {
				result = { success: false };
			}
		);
		return result;
	};

	useMemo(() => {
		(async () => {
			if (data && geocoding && state.length <= 0) {
				const { cases } = data;
				const coords = geocoding.geocoding;
				// console.log(cases, coords);
				let items = flow(groupBy("location"))(cases);
				let datas = [];
				// items = items.map((key, val) => {
				// 	console.log(key);
				// });
				const promises = Object.keys(items).map(async (k) => {
					let {
						location,
						health_status,
						public_address,
						opr,
						code,
						source,
						current_date,
						sex,
					} = items[k][0];

					if (!created) {
						setCreated(current_date);
					}

					let count = items[k].length;
					let address = location?.toLowerCase();
					let latlng = coords.filter((loc) => loc.location === address);
					if (latlng.length <= 0) {
						let result = await fetchLatLong(address);
						let { lat, lng, success } = result;
						if (success) {
							for (let x = 0; x < count; x++) {
								const data = {
									lat,
									lng,
									count,
									is_new: true,
									patient_name: `${opr}${code}`,
									health_status,
									public_address,
									address,
									source,
									sex,
								};
								datas.push(data);
							}
							return {
								lat,
								lng,
								count,
								is_new: true,
								patient_name: `${opr}${code}`,
								health_status,
								public_address,
								address,
								source,
								sex,
							};
						}
					} else {
						const { latitude, longitude } = latlng[0];
						for (let x = 0; x < count; x++) {
							const data = {
								is_new: false,
								patient_name: `${opr}${code}`,
								health_status,
								public_address,
								lat: latitude,
								lng: longitude,
								count,
								address,
								source,
								sex,
							};
							datas.push(data);
						}
						return {
							is_new: false,
							patient_name: `${opr}${code}`,
							health_status,
							public_address,
							lat: latitude,
							lng: longitude,
							count,
							address,
							source,
							sex,
						};
					}
				});
				Promise.all(promises).then((results) => {
					let data = results.filter((res) => res);
					if (datas.length) {
						setMarkers([...datas]);
					}
					if (state.length <= 0) {
						setState(data);
					}
				});
			}

			// let result = await fetchLatLong(
			// 	"mansasa tagbilaran city (capital) bohol"
			// );
		})();
	}, [data, geocoding]);

	// useMemo(() => {
	// 	if (bubbleData) {
	// 		const { bubble_map_data } = bubbleData;
	// 		console.log(bubble_map_data);
	// 		let items = bubble_map_data.map((data) => {
	// 			const { lat, lng, source, positive, location } = data;
	// 			return {
	// 				lat,
	// 				lng,
	// 				count: positive,
	// 				address: location,
	// 				source,
	// 			};
	// 		});
	// 		setMarkers([...markers, ...items]);
	// 	}
	// }, [bubbleData]);

	useEffect(() => {
		if (state.length > 0) {
			let data = state
				.filter((address) => address.is_new === true)
				.map((val) => {
					const { address, lat, lng } = val;
					return {
						latitude: lat,
						longitude: lng,
						location: address,
					};
				});
			if (data.length > 0) {
				insertGeolocation({
					variables: {
						data,
					},
				});
			}
		}
	}, [state]);

	const setGoogleMapRef = (map, maps) => {
		let googleMapRef = map;
		let googleRef = maps;

		let locations = [...markers];
		let items =
			locations &&
			locations.map((location) => {
				const {
					lat,
					lng,
					count,
					source,
					public_address,
					patient_name,
					health_status,
				} = location;
				let image = `data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='38' width='38'><circle cx='20' cy='20' r='30%25' fill='%2372bcd4' /><text x='16' y='24' fill='%23333333' font-family="monospace">${count}</text></svg>`;
				// let newLat = lat + Math.random() / 25000;
				// let newLng = lng + Math.random() / 25000;
				let newLat = lat;
				let newLng = lng;
				const marker = new googleRef.Marker({
					position: { lat: newLat, lng },
					icon: {
						url: image,
						origin: new googleRef.Point(0, 0),
						anchor: new googleRef.Point(25, 25),
					},
				});

				let name =
					count > 1 ? `No. of Case: ${count}` : `Patient: ${patient_name}`;

				let content = `<div id="content"><div id="siteNotice"></div><h5 id="firstHeading" class="firstHeading" style="padding:0!important;margin:0!important;margin-bottom:1px!important;">${public_address} </h5><p>${name}</p><p>Status: ${health_status}</p><div id="bodyContent"></div></div>`;
				// if (source === "PUM" || source === "ILI") {
				// 	content = `<div id="content"><div id="siteNotice"></div><h5 id="firstHeading" class="firstHeading">Source: LGU</h5><div id="bodyContent"></div></div>`;
				// }
				const infowindow = new googleRef.InfoWindow({
					content: content,
				});
				marker.addListener("click", () => {
					infowindow.open(map, marker);
				});
				// marker.addListener("mouseout", () => {
				// 	infowindow.close(map, marker);
				// });
				return marker;
			});
		let markerCluster = new MarkerClusterer(map, items, {
			imagePath:
				"https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
			gridSize: 20,
			minimumClusterSize: 200,
			zoomOnClick: false,
		});

		// googleRef.event.addListener(markerCluster, "clusterclick", (e) => {
		// 	googleRef.event.trigger(e, "click", function (e) {
		// 		console.log("teest");
		// 	});
		// });

		// googleRef.event.addDomListener(map, "click", function (e) {
		// 	googleRef.event.trigger(markerCluster, "click", function (e) {
		// 		console.log("hasdfsf");
		// 	});
		// });

		// setTimeout(() => {
		// 	markerCluster.clusters_.forEach((cluster) => {
		// 		console.log(cluster.markers_[0]);
		// 		cluster.addListener("clusterclick", function (e) {
		// 			console.log(e);
		// 		});
		// 		googleRef.event.trigger(cluster, "clusterclick", function (e) {
		// 			console.log(e);
		// 		});
		// 	});
		// }, 1000);
		// googleRef.event.trigger(markerCluster.clusters_, "clusterclick", function (
		// 	e
		// ) {
		// 	console.log("test", e);
		// });
		googleRef.event.addListener(markerCluster, "clusterclick", function (e) {
			console.log(e);
		});
	};

	const generateReport = (created) => {
		return (
			<ExcelFile
				element={
					<div style={{ cursor: "pointer", fontSize: 11 }}>
						Download CSV data as of <strong>{created}</strong>
					</div>
				}
				filename={"WeTrace Covid-19 Report"}
			>
				<ExcelSheet data={markers} name="WeTrace Covid-19 Tracker">
					<ExcelColumn label="Patient name" value="patient_name" />
					<ExcelColumn label="Gender" value="sex" />
					<ExcelColumn label="Source Office / Hospital" value="source" />
					<ExcelColumn label="Generic Address" value="public_address" />
					<ExcelColumn label="Status" value="health_status" />
					<ExcelColumn label="Latitude" value="lat" />
					<ExcelColumn label="Longitude" value="lng" />
				</ExcelSheet>
			</ExcelFile>
		);
	};

	const info = () => {
		return (
			<div
				style={{
					position: "absolute",
					zIndex: 1000,
					width: 320,
					height: 150,
					background: "rgba(255,255,255,0.7)",
					bottom: 20,
					right: 70,
					borderRadius: 5,
					boxShadow: "0 0 10px #999",
					padding: 10,
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<div style={{ flex: 2 }}>
						<img
							src={require("./images/wetrace-logo.jpg")}
							style={{ width: 100 }}
						/>
					</div>
					<div style={{ flex: 3 }}>
						<h4 style={{ margin: 0 }}>WeTrace Covid Tracker</h4>
					</div>
				</div>
				<p style={{ margin: 0, padding: 0, fontSize: 12, lineHeight: 1 }}>
					<small>
						Note: GPS coordinates are based on the Sitio and Street indicated in
						Google Maps and are not the exact geo points of the cases.
					</small>
				</p>
				<h6 style={{ margin: 0, marginBottom: 10, marginTop: 10 }}>
					In Partnership with
				</h6>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "center",
					}}
				>
					<img
						src={require("./images/pnp.jpg")}
						style={{ height: 25, marginRight: 10 }}
					/>
					<img
						src={require("./images/doh.jpg")}
						style={{ width: 25, marginRight: 10 }}
					/>
					<img
						src={require("./images/cebu-province.jpg")}
						style={{ width: 25, marginRight: 10 }}
					/>
					{created && generateReport(created)}
				</div>
			</div>
		);
	};

	return (
		<div style={{ height: "100vh", width: "100%" }}>
			<Media query="(min-width:799px)" render={() => info()} />
			{markers.length > 0 && (
				<GoogleMapReact
					bootstrapURLKeys={{ key: "AIzaSyBU-VUXfXc7Bt6XMNPGZBUT0pXbiE0Svhk" }}
					yesIWantToUseGoogleMapApiInternals
					onGoogleApiLoaded={({ map, maps }) => setGoogleMapRef(map, maps)}
					defaultCenter={{ lat: 10.315699, lng: 123.885437 }}
					defaultZoom={9}
					options={{ streetViewControl: true }}
				/>
			)}
		</div>
	);
};

export default GoogleMapContainer;
