import { gql } from "apollo-boost";

const FETCH_CASES = gql`
	query cases($offset: Int, $limit: Int) {
		cases(offset: $offset, limit: $limit, where: { status: { _eq: "CASES" } }) {
			location
			opr
			code
			health_status
			classification
			public_address
			source
			current_date
			sex
			device_id
			cases_philippine_residence {
				id
				lat
				lng
			}
		}
	}
`;

const FETCH_GEOCODING = gql`
	query geocoding {
		geocoding {
			location
			longitude
			latitude
		}
	}
`;

const INSERT_GEOCODING = gql`
	mutation insert_geolocation($data: [geocoding_insert_input!]!) {
		insert_geocoding(objects: $data) {
			returning {
				id
			}
		}
	}
`;

const FETCH_BUBBLE_DATA = gql`
	query bubble_data {
		bubble_map_data {
			lng
			lat
			location
			positive
			recovered
			source
			death
		}
	}
`;

const ADDRESSES = gql`
	query public_location($offset: Int) {
		cases(distinct_on: [public_address], limit: 10, offset: $offset) {
			public_address
			sitio
			barangay
			municipality
			province
		}
	}
`;

const LOGIN_USER = gql`
	mutation map_login($username: String!, $password: String!) {
		map_login(object: { username: $username, password: $password }) {
			token
		}
	}
`;

export {
	FETCH_CASES,
	FETCH_GEOCODING,
	INSERT_GEOCODING,
	FETCH_BUBBLE_DATA,
	ADDRESSES,
	LOGIN_USER,
};
