import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
	FETCH_CASES,
	FETCH_GEOCODING,
	INSERT_GEOCODING,
	FETCH_BUBBLE_DATA,
} from "./utils/queries";
import _ from "lodash";
import flow from "lodash/fp/flow";
import groupBy from "lodash/fp/groupBy";
import Geocode from "react-geocode";
import ReactExport from "react-export-excel";
import { LoadScript, GoogleMap, InfoWindow } from "@react-google-maps/api";
import MapCluster from "./markerCluster";
import Media from "react-media";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

Geocode.setApiKey("AIzaSyDynF1KrnxpCfRAd0ogn9Ui8BcxcCiXPto");

// set response language. Defaults to english.
Geocode.setLanguage("en");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("ph");

// Enable or disable logs. Its optional.
Geocode.enableDebug();

const GoogleMapNew = () => {
	const { data: data_1, loading: loading_1 } = useQuery(FETCH_CASES, {
		variables: {
			limit: 5000,
			offset: 0,
		},
	});
	const { data: data_2, loading: loading_2 } = useQuery(FETCH_CASES, {
		variables: {
			limit: 5000,
			offset: 5000,
		},
	});
	const { data: data_3, loading: loading_3 } = useQuery(FETCH_CASES, {
		variables: {
			limit: 5000,
			offset: 10000,
		},
	});
	const { data: data_4, loading: loading_4 } = useQuery(FETCH_CASES, {
		variables: {
			limit: 5000,
			offset: 15000,
		},
	});
	const {
		data: bubbleData,
		error: bubbleError,
		loading: bubbleLoading,
	} = useQuery(FETCH_BUBBLE_DATA);
	const { data: geocoding } = useQuery(FETCH_GEOCODING);
	const [insertGeolocation] = useMutation(INSERT_GEOCODING);
	const [coords, setCoords] = useState([]);
	const [state, setState] = useState([]);
	const [markers, setMarkers] = useState([]);
	const [created, setCreated] = useState(null);
	const [center, setCenter] = useState({ lat: 0, lng: 0 });
	const [showinfo, setShowinfo] = useState(false);
	const [map, setMap] = useState(null);
	const infoWindowRef = useRef();
	const mapRef = useRef();

	const [marker, setMarker] = useState({});

	const fetchLatLong = async (address) => {
		let result = null;
		await Geocode.fromAddress(address).then(
			(response) => {
				const { lat, lng } = response.results[0].geometry.location;
				result = { lat, lng, success: true };
			},
			(error) => {
				result = { success: false };
			}
		);
		return result;
	};

	useEffect(() => {
		// (async () => {
		// 	if (data && geocoding && markers.length <= 0) {
		// 		const { cases } = data;
		// 		const coords = geocoding.geocoding;
		// 		// console.log(cases, coords);
		// 		let items = flow(groupBy("location"))(cases);
		// 		let datas = [];
		// 		// items = items.map((key, val) => {
		// 		// 	console.log(key);
		// 		// });
		// 		const promises = Object.keys(items).map(async (k) => {
		// 			let {
		// 				location,
		// 				health_status,
		// 				public_address,
		// 				opr,
		// 				code,
		// 				source,
		// 				current_date,
		// 				sex,
		// 				device_id,
		// 			} = items[k][0];

		// 			if (!created) {
		// 				setCreated(current_date);
		// 			}

		// 			let count = items[k].length;
		// 			let address = public_address?.toLowerCase();
		// 			let latlng = coords.filter((loc) => loc.location === public_address);
		// 			console.log(latlng);
		// 			if (latlng.length) {
		// 				const { latitude, longitude } = latlng[0];
		// 				for (let x = 0; x < count; x++) {
		// 					const data = {
		// 						is_new: false,
		// 						patient_name: `${opr}${code}`,
		// 						health_status,
		// 						public_address,
		// 						lat: latitude,
		// 						lng: longitude,
		// 						count,
		// 						address,
		// 						source,
		// 						sex,
		// 						device_id,
		// 					};
		// 					datas.push(data);
		// 				}
		// 				return {
		// 					is_new: false,
		// 					patient_name: `${opr}${code}`,
		// 					health_status,
		// 					public_address,
		// 					lat: latitude,
		// 					lng: longitude,
		// 					count,
		// 					address,
		// 					source,
		// 					sex,
		// 					device_id,
		// 				};
		// 			}
		// 			// else {
		// 			// 	const { latitude, longitude } = latlng[0];
		// 			// 	for (let x = 0; x < count; x++) {
		// 			// 		const data = {
		// 			// 			is_new: false,
		// 			// 			patient_name: `${opr}${code}`,
		// 			// 			health_status,
		// 			// 			public_address,
		// 			// 			lat: latitude,
		// 			// 			lng: longitude,
		// 			// 			count,
		// 			// 			address,
		// 			// 			source,
		// 			// 			sex,
		// 			// 			device_id,
		// 			// 		};
		// 			// 		datas.push(data);
		// 			// 	}
		// 			// 	return {
		// 			// 		is_new: false,
		// 			// 		patient_name: `${opr}${code}`,
		// 			// 		health_status,
		// 			// 		public_address,
		// 			// 		lat: latitude,
		// 			// 		lng: longitude,
		// 			// 		count,
		// 			// 		address,
		// 			// 		source,
		// 			// 		sex,
		// 			// 		device_id,
		// 			// 	};
		// 			// }
		// 		});
		// 		Promise.all(promises).then((results) => {
		// 			let data = results.filter((res) => res);
		// 			if (datas.length) {
		// 				setMarkers([...datas]);
		// 			}
		// 			// if (state.length <= 0) {
		// 			// 	setState(data);
		// 			// }
		// 		});
		// 	}
		// })();
		if (data_1?.cases && data_2?.cases && data_3?.cases && data_4?.cases) {
			// console.
			const markers_1 = data_1.cases.map((val) => {
				const {
					public_address,
					cases_philippine_residence,
					classification,
					opr,
					code,
				} = val;
				return {
					public_address,
					patient_name: `${opr}${code}`,
					lat: cases_philippine_residence[0]?.lat
						? parseFloat(cases_philippine_residence[0]?.lat)
						: null,
					lng: cases_philippine_residence[0]?.lng
						? parseFloat(cases_philippine_residence[0]?.lng)
						: null,
					health_status: classification,
				};
			});
			const markers_2 = data_2.cases.map((val) => {
				const {
					public_address,
					cases_philippine_residence,
					classification,
					opr,
					code,
				} = val;
				return {
					public_address,
					patient_name: `${opr}${code}`,
					lat: cases_philippine_residence[0]?.lat
						? parseFloat(cases_philippine_residence[0]?.lat)
						: null,
					lng: cases_philippine_residence[0]?.lng
						? parseFloat(cases_philippine_residence[0]?.lng)
						: null,
					health_status: classification,
				};
			});
			const markers_3 = data_3.cases.map((val) => {
				const {
					public_address,
					cases_philippine_residence,
					classification,
					opr,
					code,
				} = val;
				return {
					public_address,
					patient_name: `${opr}${code}`,
					lat: cases_philippine_residence[0]?.lat
						? parseFloat(cases_philippine_residence[0]?.lat)
						: null,
					lng: cases_philippine_residence[0]?.lng
						? parseFloat(cases_philippine_residence[0]?.lng)
						: null,
					health_status: classification,
				};
			});
			const markers_4 = data_4.cases.map((val) => {
				const {
					public_address,
					cases_philippine_residence,
					classification,
					opr,
					code,
				} = val;
				return {
					public_address,
					patient_name: `${opr}${code}`,
					lat: cases_philippine_residence[0]?.lat
						? parseFloat(cases_philippine_residence[0]?.lat)
						: null,
					lng: cases_philippine_residence[0]?.lng
						? parseFloat(cases_philippine_residence[0]?.lng)
						: null,
					health_status: classification,
				};
			});
			setMarkers([...markers_1, ...markers_2, ...markers_3, ...markers_4]);
		}
		// console.log(data);
	}, [data_1, data_2, data_3, data_4]);
	console.log(markers, "markers");
	// useEffect(() => {
	// 	if (state.length > 0) {
	// 		let data = state
	// 			.filter((address) => address.is_new === true)
	// 			.map((val) => {
	// 				const { address, lat, lng } = val;
	// 				return {
	// 					latitude: lat,
	// 					longitude: lng,
	// 					location: address,
	// 				};
	// 			});
	// 		if (data.length > 0) {
	// 			insertGeolocation({
	// 				variables: {
	// 					data,
	// 				},
	// 			});
	// 		}
	// 	}
	// }, [state]);

	const generateReport = (created) => {
		return (
			<ExcelFile
				element={
					<div style={{ cursor: "pointer", fontSize: 11 }}>
						Download CSV data as of <strong>{created}</strong>
					</div>
				}
				filename={"WeTrace Covid-19 Report"}
			>
				<ExcelSheet data={markers} name="WeTrace Covid-19 Tracker">
					<ExcelColumn label="Patient name" value="patient_name" />
					<ExcelColumn label="Gender" value="sex" />
					<ExcelColumn label="Source Office / Hospital" value="source" />
					<ExcelColumn label="Generic Address" value="public_address" />
					<ExcelColumn label="Status" value="health_status" />
					<ExcelColumn label="Latitude" value="lat" />
					<ExcelColumn label="Longitude" value="lng" />
				</ExcelSheet>
			</ExcelFile>
		);
	};

	const info = () => {
		console.log("herer");
		return (
			<>
				<div
					style={{
						position: "absolute",
						zIndex: 1000,
						width: 320,
						height: 150,
						background: "rgba(255,255,255,0.7)",
						bottom: 20,
						right: 70,
						borderRadius: 5,
						boxShadow: "0 0 10px #999",
						padding: 10,
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<div style={{ flex: 2 }}>
							<img
								src={require("./images/wetrace-logo.jpg")}
								style={{ width: 100 }}
							/>
						</div>
						<div style={{ flex: 3 }}>
							<h4 style={{ margin: 0 }}>WeTrace Covid Tracker</h4>
						</div>
					</div>
					<p style={{ margin: 0, padding: 0, fontSize: 12, lineHeight: 1 }}>
						<small>
							Note: GPS coordinates are based on the Sitio and Street indicated
							in Google Maps and are not the exact geo points of the cases.
						</small>
					</p>
					<h6 style={{ margin: 0, marginBottom: 10, marginTop: 10 }}>
						In Partnership with
					</h6>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-start",
							alignItems: "center",
						}}
					>
						<img
							src={require("./images/pnp.jpg")}
							style={{ height: 25, marginRight: 10 }}
						/>
						<img
							src={require("./images/doh.jpg")}
							style={{ width: 25, marginRight: 10 }}
						/>
						<img
							src={require("./images/cebu-province.jpg")}
							style={{ width: 25, marginRight: 10 }}
						/>
						{created && generateReport(created)}
					</div>
				</div>
			</>
		);
	};

	const infoboxOption = { closeBoxURL: "", enableEventPropagation: true };

	return (
		<div style={{ height: "100vh", width: "100%" }}>
			{/* <Media query="(min-width:799px)" render={() => info()} /> */}
			<a
				onClick={() => window.location.reload()}
				style={{
					position: "absolute",
					top: 60,
					right: 10,
					background: "white",
					borderRadius: 3,
					padding: 5,
					zIndex: 1000,
					fontSize: 11,
					boxShadow: "0 0 10px #999",
				}}
			>
				Reload Map
			</a>
			{markers.length > 0 && (
				<LoadScript
					googleMapsApiKey={"AIzaSyDynF1KrnxpCfRAd0ogn9Ui8BcxcCiXPto"}
				>
					<GoogleMap
						mapContainerStyle={{ width: "100%", height: "100vh" }}
						center={{ lat: 10.315699, lng: 123.885437 }}
						zoom={9}
						onLoad={(map) => setMap(map)}
						ref={mapRef}
					>
						<MapCluster
							markers={markers}
							setCenter={setCenter}
							infoWindowRef={infoWindowRef}
							showinfo={setShowinfo}
							map={map}
							mapRef={mapRef}
						/>
					</GoogleMap>
				</LoadScript>
			)}
		</div>
	);
};

export default GoogleMapNew;
