import React, { useState, useRef } from "react";
import { MarkerClusterer, InfoWindow } from "@react-google-maps/api";
import MapMarker from "./MapMarker";

const MapCluster = (props) => {
	const { markers, infoWindowRef, showinfo, map, mapRef } = props;
	const [center, setCenter] = useState({ lat: 0, lng: 0 });
	const [infoWindowOpen, setInfoWindowOpen] = useState(false);
	const infoRef = useRef();

	console.log(mapRef);

	const options = {
		imagePath:
			"https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
	};

	const makeid = (length) => {
		var result = "";
		var characters =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	};

	const clusterDetail = () => {
		return (
			<InfoWindow ref={infoRef}>
				<div>test</div>
			</InfoWindow>
		);
	};

	return (
		<MarkerClusterer
			options={options}
			zoomOnClick={false}
			onClick={(cluster) => {
				let customMarker = cluster.getMarkers();
				let isequal = true;
				for (let x = 0; x < customMarker.length; x++) {
					let old = x;
					let next = x + 1;

					if (next < customMarker.length) {
						if (
							customMarker[old].position.lat().toFixed(5) !==
								customMarker[next].position.lat().toFixed(5) &&
							customMarker[old].position.lng().toFixed(5) !==
								customMarker[next].position.lng().toFixed(5)
						) {
							isequal = false;
							break;
						}
					}
				}

				if (isequal) {
					const upperLat = cluster.getMarkers()[0].position.lat().toFixed(4);
					const lowerLat = upperLat - 0.0001;
					const upperLng = cluster.getMarkers()[0].position.lng().toFixed(4);
					const lowerLng = upperLng - 0.0001;

					let search = markers.filter((marker) => {
						let comparelat = marker.lat.toFixed(4);
						let comparelng = marker.lng.toFixed(4);
						if (
							comparelat <= upperLat &&
							comparelat >= lowerLat &&
							comparelng >= upperLng &&
							comparelng >= lowerLng
						) {
							return marker;
						}
					});

					const infowindow = new window.google.maps.InfoWindow({
						content: `<div><h3>${
							search[0].public_address
						}</h3><h4>Total cases: ${cluster.getMarkers().length}</h4></div>`,
					});
					infowindow.setPosition(cluster.getCenter());
					infowindow.open(map, cluster);
				} else {
					map.setCenter(cluster.getCenter());
					map.setZoom(map.getZoom() + 1);
				}
			}}
			gridSize={30}
			minimumClusterSize={5}
		>
			{(clusterer) =>
				markers.map((marker) => {
					const {
						lat,
						lng,
						count,
						source,
						public_address,
						patient_name,
						health_status,
						device_id,
					} = marker;

					let name =
						count > 1 ? `No. of Case: ${count}` : `Patient: ${patient_name}`;
					let randomId = makeid(30);
					return (
						<MapMarker
							lat={lat}
							lng={lng}
							clusterer={clusterer}
							key={randomId}
							address={public_address}
							status={health_status}
							name={patient_name}
							map={map}
						/>
					);
				})
			}
		</MarkerClusterer>
	);
};

export default MapCluster;
