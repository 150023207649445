import React, { useMemo, useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import MarkerClusterer from "@google/markerclusterer";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { FETCH_BUBBLE_DATA } from "./utils/queries";
import _ from "lodash";
import tap from "lodash/fp/tap";
import flow from "lodash/fp/flow";
import groupBy from "lodash/fp/groupBy";
import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyBP5hWnrSbLH3FzHQoS-8kXfXNtidCNlJE");

// set response language. Defaults to english.
Geocode.setLanguage("en");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("ph");

// Enable or disable logs. Its optional.
Geocode.enableDebug();

const Marker = ({ text }) => (
	<div
		style={{
			width: 50,
			height: 50,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		}}
	>
		{text}
	</div>
);

const AnyReactComponent = ({ positive }) => {
	const markerStyle = {
		width:
			positive < 20
				? 15
				: positive < 20 && positive < 50
				? 20
				: positive > 50 && positive < 100
				? 30
				: positive > 100 && positive < 150
				? 40
				: positive > 150 && positive < 200
				? 50
				: positive > 200 && positive < 300
				? 60
				: positive > 300 && positive < 400
				? 70
				: 80,
		height:
			positive < 20
				? 15
				: positive < 20 && positive < 50
				? 20
				: positive > 50 && positive < 100
				? 30
				: positive > 100 && positive < 150
				? 40
				: positive > 150 && positive < 200
				? 50
				: positive > 200 && positive < 300
				? 60
				: positive > 300 && positive < 400
				? 70
				: 80,
		display: "flex",
		borderRadius: 200,
		alignItems: "center",
		justifyContent: "center",
		background: "#006633",
		color: "white",
		border: "solid 1px #ffffff",
	};
	return <div style={markerStyle}>{positive}</div>;
};

const CustomMap = () => {
	// componentDidMount() {
	// 	const script = document.createElement("script");
	// 	script.src =
	// 		"https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js";
	// 	script.async = true;
	// 	document.body.appendChild(script);
	// }

	// const [googleMapRef, setGoogleMapRef] = useState(null);
	// const [googleRef, setGoogleRef] = useState(null);

	const { data, error, loading } = useQuery(FETCH_BUBBLE_DATA);
	const [state, setState] = useState([]);

	useMemo(() => {
		if (data) {
			const { bubble_map_data } = data;
			console.log(bubble_map_data, "data");
			setState(bubble_map_data);
		}
	}, [data]);

	return (
		<div style={{ height: "100vh", width: "100%" }}>
			<GoogleMapReact
				bootstrapURLKeys={{ key: "AIzaSyBU-VUXfXc7Bt6XMNPGZBUT0pXbiE0Svhk" }}
				defaultCenter={{ lat: 10.315699, lng: 123.885437 }}
				defaultZoom={13}
			>
				{state.map((marker) => {
					return (
						<AnyReactComponent
							lat={marker.lat}
							lng={marker.lng}
							positive={marker.positive}
						/>
					);
				})}
			</GoogleMapReact>
		</div>
	);
};

export default CustomMap;
